import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonError, isCommonErrorJson } from '../../../common-error';
import { LifecycleService } from '../../../lifecycle-service';
import * as Admin from '../../types/admin-api/admin';
import * as Auth from '../../types/admin-api/auth';
import * as Board from '../../types/admin-api/board';
import * as Celeb from '../../types/admin-api/celeb';
import * as CelebPoint from '../../types/admin-api/celeb-point';
import * as Chat from '../../types/admin-api/chat';
import * as Dashboard from '../../types/admin-api/dashboard';
import * as Donation from '../../types/admin-api/donation';
import * as Notice from '../../types/admin-api/notice';
import * as Order from '../../types/admin-api/order';
import * as Point from '../../types/admin-api/point';
import * as Settings from '../../types/admin-api/settings';
import * as Subscription from '../../types/admin-api/subscription';
import * as User from '../../types/admin-api/user';
import * as Watermark from '../../types/admin-api/watermark';
import { DateWrapped } from '../../types/date-wrapper';
import { unwrapDate, wrapDate } from '../../utils/date-wrapper';
import { getFileName } from '../../utils/get-file-name';
import { paramsToQueryString } from '../../utils/query-string';
import { APIResponse, APIResponseFailure } from './api.types';

const API_URL = environment.apiUrl;

interface HeadersMap {
  [header: string]: string | string[];
}

@Injectable({
  providedIn: 'root',
})
export class ApiService extends LifecycleService {
  private accessToken: string | null = null;

  private accessToken$Subject = new BehaviorSubject<Observable<string | null>>(of(null));

  constructor(private httpClient: HttpClient) {
    super();

    this.accessToken$Subject
      .pipe(
        switchMap((accessToken$) => accessToken$),
        this.takeUntilDestroy()
      )
      .subscribe((accessToken) => {
        this.accessToken = accessToken;
      });
  }

  init(accessToken$: Observable<string | null>): void {
    this.accessToken$Subject.next(accessToken$);
  }

  getAdminList(params: Admin.GetAdminListQueryParams) {
    return this.get<Admin.GetAdminListResult>('/v1/admin/list', params);
  }
  getAdminDetail(params: Admin.GetAdminDetailQueryParams) {
    return this.get<Admin.GetAdminDetailResult>('/v1/admin/detail', params);
  }

  postAuthSignIn(params: Auth.PostAuthSignInParams) {
    return this.post<Auth.PostAuthSignInResult>('/v1/auth/sign-in', params);
  }
  postAuthSignOut() {
    return this.post<Auth.PostAuthSignOutResult>('/v1/auth/sign-out', {});
  }

  getBoardBoardDetail(params: Board.GetBoardBoardDetailQueryParams) {
    return this.get<Board.GetBoardBoardDetailResult>('/v1/board/board-detail', params);
  }
  getBoardBoardList(params: Board.GetBoardBoardListQueryParams) {
    return this.get<Board.GetBoardBoardListResult>('/v1/board/board-list', params);
  }
  getBoardCommentDetail(params: Board.GetBoardCommentDetailQueryParams) {
    return this.get<Board.GetBoardCommentDetailResult>('/v1/board/comment-detail', params);
  }
  getBoardCommentList(params: Board.GetBoardCommentListQueryParams) {
    return this.get<Board.GetBoardCommentListResult>('/v1/board/comment-list', params);
  }
  postBoardDeleteComment(params: Board.PostBoardDeleteCommentParams) {
    return this.post<Board.PostBoardDeleteCommentResult>('/v1/board/delete-comment', params);
  }
  postBoardDeletePost(params: Board.PostBoardDeletePostParams) {
    return this.post<Board.PostBoardDeletePostResult>('/v1/board/delete-post', params);
  }
  getBoardPostDetail(params: Board.GetBoardPostDetailQueryParams) {
    return this.get<Board.GetBoardPostDetailResult>('/v1/board/post-detail', params);
  }
  getBoardPostList(params: Board.GetBoardPostListQueryParams) {
    return this.get<Board.GetBoardPostListResult>('/v1/board/post-list', params);
  }
  getBoardReportCommentDetail(params: Board.GetBoardReportCommentDetailQueryParams) {
    return this.get<Board.GetBoardReportCommentDetailResult>('/v1/board/report-comment-detail', params);
  }
  getBoardReportCommentList(params: Board.GetBoardReportCommentListQueryParams) {
    return this.get<Board.GetBoardReportCommentListResult>('/v1/board/report-comment-list', params);
  }
  getBoardReportPostDetail(params: Board.GetBoardReportPostDetailQueryParams) {
    return this.get<Board.GetBoardReportPostDetailResult>('/v1/board/report-post-detail', params);
  }
  getBoardReportPostList(params: Board.GetBoardReportPostListQueryParams) {
    return this.get<Board.GetBoardReportPostListResult>('/v1/board/report-post-list', params);
  }
  postBoardUpdateReportComment(params: Board.PostBoardUpdateReportCommentParams) {
    return this.post<Board.PostBoardUpdateReportCommentResult>('/v1/board/update-report-comment', params);
  }
  postBoardUpdateReportPost(params: Board.PostBoardUpdateReportPostParams) {
    return this.post<Board.PostBoardUpdateReportPostResult>('/v1/board/update-report-post', params);
  }

  getCelebApplicationList(params: Celeb.GetCelebApplicationListQueryParams) {
    return this.get<Celeb.GetCelebApplicationListResult>('/v1/celeb/application-list', params);
  }
  getCelebApplicationDetail(params: Celeb.GetCelebApplicationDetailQueryParams) {
    return this.get<Celeb.GetCelebApplicationDetailResult>('/v1/celeb/application-detail', params);
  }
  postCelebApproveApplication(params: Celeb.PostCelebApproveApplicationParams) {
    return this.post<Celeb.PostCelebApproveApplicationResult>('/v1/celeb/approve-application', params);
  }
  getCelebCelebRankHistory(params: Celeb.GetCelebCelebRankHistoryQueryParams) {
    return this.get<Celeb.GetCelebCelebRankHistoryResult>('/v1/celeb/celeb-rank-history', params);
  }
  postCelebCompleteSettlement(params: Celeb.PostCelebCompleteSettlementParams) {
    return this.post<Celeb.PostCelebCompleteSettlementResult>('/v1/celeb/complete-settlement', params);
  }
  getCelebDetail(params: Celeb.GetCelebDetailQueryParams) {
    return this.get<Celeb.GetCelebDetailResult>('/v1/celeb/detail', params);
  }
  getCelebDMChatPointCollectionDetail(params: Celeb.GetCelebDMChatPointCollectionDetailQueryParams) {
    return this.get<Celeb.GetCelebDMChatPointCollectionDetailResult>('/v1/celeb/dm-chat-point-collection-detail', params);
  }
  getCelebDMChatPointCollectionList(params: Celeb.GetCelebDMChatPointCollectionListQueryParams) {
    return this.get<Celeb.GetCelebDMChatPointCollectionListResult>('/v1/celeb/dm-chat-point-collection-list', params);
  }
  getCelebList(params: Celeb.GetCelebListQueryParams) {
    return this.get<Celeb.GetCelebListResult>('/v1/celeb/list', params);
  }
  getCelebPrivateRanking(params: Celeb.GetCelebPrivateRankingQueryParams) {
    return this.get<Celeb.GetCelebPrivateRankingResult>('/v1/celeb/private-ranking', params);
  }
  postCelebRejectApplication(params: Celeb.PostCelebRejectApplicationParams) {
    return this.post<Celeb.PostCelebRejectApplicationResult>('/v1/celeb/reject-application', params);
  }
  postCelebRejectSettlement(params: Celeb.PostCelebRejectSettlementParams) {
    return this.post<Celeb.PostCelebRejectSettlementResult>('/v1/celeb/reject-settlement', params);
  }
  postCelebSetApplicationCelebRank(params: Celeb.PostCelebSetApplicationCelebRankParams) {
    return this.post<Celeb.PostCelebSetApplicationCelebRankResult>('/v1/celeb/set-application-celeb-rank', params);
  }
  postCelebSetApplicationSnsVerified(params: Celeb.PostCelebSetApplicationSnsVerifiedParams) {
    return this.post<Celeb.PostCelebSetApplicationSnsVerifiedResult>('/v1/celeb/set-application-sns-verified', params);
  }
  postCelebSetCelebRank(params: Celeb.PostCelebSetCelebRankParams) {
    return this.post<Celeb.PostCelebSetCelebRankResult>('/v1/celeb/set-celeb-rank', params);
  }
  postCelebSetSettlementSending(params: Celeb.PostCelebSetSettlementSendingParams) {
    return this.post<Celeb.PostCelebSetSettlementSendingResult>('/v1/celeb/set-settlement-sending', params);
  }
  getCelebSettlementDetail(params: Celeb.GetCelebSettlementDetailQueryParams) {
    return this.get<Celeb.GetCelebSettlementDetailResult>('/v1/celeb/settlement-detail', params);
  }
  getCelebSettlementList(params: Celeb.GetCelebSettlementListQueryParams) {
    return this.get<Celeb.GetCelebSettlementListResult>('/v1/celeb/settlement-list', params);
  }
  postCelebSuspendDelete(params: Celeb.PostCelebSuspendDeleteParams) {
    return this.post<Celeb.PostCelebSuspendDeleteResult>('/v1/celeb/suspend-delete', params);
  }
  getCelebSuspendList(params: Celeb.GetCelebSuspendListQueryParams) {
    return this.get<Celeb.GetCelebSuspendListResult>('/v1/celeb/suspend-list', params);
  }
  postCelebSuspend(params: Celeb.PostCelebSuspendParams) {
    return this.post<Celeb.PostCelebSuspendResult>('/v1/celeb/suspend', params);
  }
  postCelebUnregisterCeleb(params: Celeb.PostCelebUnregisterCelebParams) {
    return this.post<Celeb.PostCelebUnregisterCelebResult>('/v1/celeb/unregister-celeb', params);
  }

  getCelebPointCelebSummary(params: CelebPoint.GetCelebPointCelebSummaryQueryParams) {
    return this.get<CelebPoint.GetCelebPointCelebSummaryResult>('/v1/celeb-point/celeb-summary', params);
  }
  getCelebPointCollectableMessageList(params: CelebPoint.GetCelebPointCollectableMessageListQueryParams) {
    return this.get<CelebPoint.GetCelebPointCollectableMessageListResult>('/v1/celeb-point/collectable-message-list', params);
  }
  getCelebPointCollectionEventList(params: CelebPoint.GetCelebPointCollectionEventListQueryParams) {
    return this.get<CelebPoint.GetCelebPointCollectionEventListResult>('/v1/celeb-point/collection-event-list', params);
  }
  getCelebPointReturnedMessageList(params: CelebPoint.GetCelebPointReturnedMessageListQueryParams) {
    return this.get<CelebPoint.GetCelebPointReturnedMessageListResult>('/v1/celeb-point/returned-message-list', params);
  }
  getCelebPointStatsSettlement(params: CelebPoint.GetCelebPointStatsSettlementQueryParams) {
    return this.get<CelebPoint.GetCelebPointStatsSettlementResult>('/v1/celeb-point/stats-settlement', params);
  }

  getChatDetail(params: Chat.GetChatDetailQueryParams) {
    return this.get<Chat.GetChatDetailResult>('/v1/chat/detail', params);
  }
  getChatList(params: Chat.GetChatListQueryParams) {
    return this.get<Chat.GetChatListResult>('/v1/chat/list', params);
  }
  getChatReportDetail(params: Chat.GetChatReportDetailQueryParams) {
    return this.get<Chat.GetChatReportDetailResult>('/v1/chat/report-detail', params);
  }
  getChatReportList(params: Chat.GetChatReportListQueryParams) {
    return this.get<Chat.GetChatReportListResult>('/v1/chat/report-list', params);
  }
  getChatSuperTextList(params: Chat.GetChatSuperTextListQueryParams) {
    return this.get<Chat.GetChatSuperTextListResult>('/v1/chat/super-text-list', params);
  }
  postChatUpdate(params: Chat.PostChatUpdateParams) {
    return this.post<Chat.PostChatUpdateResult>('/v1/chat/update', params);
  }
  postChatUpdateReport(params: Chat.PostChatUpdateReportParams) {
    return this.post<Chat.PostChatUpdateReportResult>('/v1/chat/update-report', params);
  }

  getDashboardDashboard(params: Dashboard.GetDashboardDashboardQueryParams) {
    return this.get<Dashboard.GetDashboardDashboardResult>('/v1/dashboard/dashboard', params);
  }

  getDonationDetail(params: Donation.GetDonationDetailQueryParams) {
    return this.get<Donation.GetDonationDetailResult>('/v1/donation/detail', params);
  }
  getDonationList(params: Donation.GetDonationListQueryParams) {
    return this.get<Donation.GetDonationListResult>('/v1/donation/list', params);
  }

  postNoticeCreate(params: Notice.PostNoticeCreateParams) {
    return this.post<Notice.PostNoticeCreateResult>('/v1/notice/create', params);
  }
  postNoticeDelete(params: Notice.PostNoticeDeleteParams) {
    return this.post<Notice.PostNoticeDeleteResult>('/v1/notice/delete', params);
  }
  getNoticeDetail(params: Notice.GetNoticeDetailQueryParams) {
    return this.get<Notice.GetNoticeDetailResult>('/v1/notice/detail', params);
  }
  getNoticeList(params: Notice.GetNoticeListQueryParams) {
    return this.get<Notice.GetNoticeListResult>('/v1/notice/list', params);
  }
  postNoticeUpdate(params: Notice.PostNoticeUpdateParams) {
    return this.post<Notice.PostNoticeUpdateResult>('/v1/notice/update', params);
  }

  postOrderCancel(params: Order.PostOrderCancelParams) {
    return this.post<Order.PostOrderCancelResult>('/v1/order/cancel', params);
  }
  postOrderCheckPayment(params: Order.PostOrderCheckPaymentParams) {
    return this.post<Order.PostOrderCheckPaymentResult>('/v1/order/check-payment', params);
  }

  getPointDailyOrderCompleted(params: Point.GetPointDailyOrderCompletedQueryParams) {
    return this.get<Point.GetPointDailyOrderCompletedResult>('/v1/point/daily-order-completed', params);
  }
  getPointDailyPointUsage(params: Point.GetPointDailyPointUsageQueryParams) {
    return this.get<Point.GetPointDailyPointUsageResult>('/v1/point/daily-point-usage', params);
  }
  getPointEventDetail(params: Point.GetPointEventDetailQueryParams) {
    return this.get<Point.GetPointEventDetailResult>('/v1/point/event-detail', params);
  }
  getPointEventList(params: Point.GetPointEventListQueryParams) {
    return this.get<Point.GetPointEventListResult>('/v1/point/event-list', params);
  }
  getPointFreePointObtainList(params: Point.GetPointFreePointObtainListQueryParams) {
    return this.get<Point.GetPointFreePointObtainListResult>('/v1/point/free-point-obtain-list', params);
  }
  getPointOrderDetail(params: Point.GetPointOrderDetailQueryParams) {
    return this.get<Point.GetPointOrderDetailResult>('/v1/point/order-detail', params);
  }
  getPointOrderList(params: Point.GetPointOrderListQueryParams) {
    return this.get<Point.GetPointOrderListResult>('/v1/point/order-list', params);
  }
  getPointStatsSales(params: Point.GetPointStatsSalesQueryParams) {
    return this.get<Point.GetPointStatsSalesResult>('/v1/point/stats-sales', params);
  }

  getSettingsRead(params: Settings.GetSettingsReadQueryParams) {
    return this.get<Settings.GetSettingsReadResult>('/v1/settings/read', params);
  }
  postSettingsUpdate(params: Settings.PostSettingsUpdateParams) {
    return this.post<Settings.PostSettingsUpdateResult>('/v1/settings/update', params);
  }

  getSubscriptionPremiumCancelDetail(params: Subscription.GetSubscriptionPremiumCancelDetailQueryParams) {
    return this.get<Subscription.GetSubscriptionPremiumCancelDetailResult>('/v1/subscription/premium-cancel-detail', params);
  }
  getSubscriptionPremiumCancelList(params: Subscription.GetSubscriptionPremiumCancelListQueryParams) {
    return this.get<Subscription.GetSubscriptionPremiumCancelListResult>('/v1/subscription/premium-cancel-list', params);
  }
  getSubscriptionPremiumDetail(params: Subscription.GetSubscriptionPremiumDetailQueryParams) {
    return this.get<Subscription.GetSubscriptionPremiumDetailResult>('/v1/subscription/premium-detail', params);
  }
  getSubscriptionPremiumList(params: Subscription.GetSubscriptionPremiumListQueryParams) {
    return this.get<Subscription.GetSubscriptionPremiumListResult>('/v1/subscription/premium-list', params);
  }
  postSubscriptionTakePremium(params: Subscription.PostSubscriptionTakePremiumParams) {
    return this.post<Subscription.PostSubscriptionTakePremiumResult>('/v1/subscription/take-premium', params);
  }

  postUserAddPoint(params: User.PostUserAddPointParams) {
    return this.post<User.PostUserAddPointResult>('/v1/user/add-point', params);
  }
  getUserBlockList(params: User.GetUserBlockListQueryParams) {
    return this.get<User.GetUserBlockListResult>('/v1/user/block-list', params);
  }
  getUserList(params: User.GetUserListQueryParams) {
    return this.get<User.GetUserListResult>('/v1/user/list', params);
  }
  getUserDetail(params: User.GetUserDetailQueryParams) {
    return this.get<User.GetUserDetailResult>('/v1/user/detail', params);
  }
  getUserLogDownloadList(params: User.GetUserLogDownloadListQueryParams) {
    return this.get<User.GetUserLogDownloadListResult>('/v1/user/log-download-list', params);
  }
  getUserLogFileViewList(params: User.GetUserLogFileViewListQueryParams) {
    return this.get<User.GetUserLogFileViewListResult>('/v1/user/log-file-view-list', params);
  }
  getUserMessagePointUsageList(params: User.GetUserMessagePointUsageListQueryParams) {
    return this.get<User.GetUserMessagePointUsageListResult>('/v1/user/message-point-usage-list', params);
  }
  getUserMuteList(params: User.GetUserMuteListQueryParams) {
    return this.get<User.GetUserMuteListResult>('/v1/user/mute-list', params);
  }
  postUserRefreshSubscription(params: User.PostUserRefreshSubscriptionParams) {
    return this.post<User.PostUserRefreshSubscriptionResult>('/v1/user/refresh-subscription', params);
  }
  getUserReportDetail(params: User.GetUserReportDetailQueryParams) {
    return this.get<User.GetUserReportDetailResult>('/v1/user/report-detail', params);
  }
  getUserReportList(params: User.GetUserReportListQueryParams) {
    return this.get<User.GetUserReportListResult>('/v1/user/report-list', params);
  }
  getUserScreenshotList(params: User.GetUserScreenshotListQueryParams) {
    return this.get<User.GetUserScreenshotListResult>('/v1/user/screenshot-list', params);
  }
  postSendPush(params: User.PostUserSendPushParams) {
    return this.post<User.PostUserSendPushResult>('/v1/user/send-push', params);
  }
  postSessionDelete(params: User.PostUserSessionDeleteParams) {
    return this.post<User.PostUserSessionDeleteResult>('/v1/user/session-delete', params);
  }
  getUserSessionList(params: User.GetUserSessionListQueryParams) {
    return this.get<User.GetUserSessionListResult>('/v1/user/session-list', params);
  }
  getUserSessionDetail(params: User.GetUserSessionDetailQueryParams) {
    return this.get<User.GetUserSessionDetailResult>('/v1/user/session-detail', params);
  }
  postUserSetProfilePhoto(params: User.PostUserSetProfilePhotoParams) {
    return this.post<User.PostUserSetProfilePhotoResult>('/v1/user/set-profile-photo', params);
  }
  postUserSuspendDelete(params: User.PostUserSuspendDeleteParams) {
    return this.post<User.PostUserSuspendDeleteResult>('/v1/user/suspend-delete', params);
  }
  getUserSuspendList(params: User.GetUserSuspendListQueryParams) {
    return this.get<User.GetUserSuspendListResult>('/v1/user/suspend-list', params);
  }
  postUserSuspend(params: User.PostUserSuspendParams) {
    return this.post<User.PostUserSuspendResult>('/v1/user/suspend', params);
  }
  postUserUpdateReport(params: User.PostUserUpdateReportParams) {
    return this.post<User.PostUserUpdateReportResult>('/v1/user/update-report', params);
  }
  postUserUploadProfile(params: User.PostUserUploadProfileParams) {
    return this.post<User.PostUserUploadProfileResult>('/v1/user/upload-profile', this.toFormData(params));
  }

  getWatermarkDetectionDetail(params: Watermark.GetWatermarkDetectionOrderDetailQueryParams) {
    return this.get<Watermark.GetWatermarkDetectionOrderDetailResult>('/v1/watermark/detection-order-detail', params);
  }
  getWatermarkDetectionList(params: Watermark.GetWatermarkDetectionOrderListQueryParams) {
    return this.get<Watermark.GetWatermarkDetectionOrderListResult>('/v1/watermark/detection-order-list', params);
  }
  postWatermarkUploadForDetectionParams(params: Watermark.PostWatermarkUploadForDetectionParams) {
    return this.post<Watermark.PostWatermarkUploadForDetectionResult>('/v1/watermark/upload-for-detection', this.toFormData(params));
  }

  setAccessToken(accessToken: string | null): void {
    this.accessToken = accessToken;
  }

  private get<T>(url: string, params?: any): Observable<APIResponse<T>> {
    const headers = this.getHeaders();
    const queryString = paramsToQueryString(params);
    return this.httpClient.get<APIResponse<DateWrapped<T>>>(API_URL + url + (queryString ? '?' + queryString : ''), { headers }).pipe(
      map((res) => ({
        ...res,
        result: unwrapDate(res.result),
      })),
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 0) {
            throw new Error('네트워크 오류가 발생했습니다.');
          }
          const res = err.error as APIResponseFailure;
          if (res != null && typeof res === 'object') {
            console.log(res);
            if (isCommonErrorJson(res.error)) {
              throw new CommonError(res.error);
            } else {
              throw new Error(res.error.message);
            }
          }
        }
        throw err;
      })
    );
  }

  private post<T>(url: string, params?: any): Observable<APIResponse<T>> {
    const headers = this.getHeaders();
    return this.httpClient
      .post<APIResponse<DateWrapped<T>>>(API_URL + url, wrapDate(params), {
        headers,
      })
      .pipe(
        map((res) => ({
          ...res,
          result: unwrapDate(res.result),
        })),
        catchError((err) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 0) {
              throw new Error('네트워크 오류가 발생했습니다.');
            }
            const res = err.error as APIResponseFailure;
            if (res != null && typeof res === 'object') {
              console.log(res);
              if (isCommonErrorJson(res.error)) {
                throw new CommonError(res.error);
              } else {
                throw new Error(res.error.message);
              }
            }
          }
          throw err;
        })
      );
  }

  private getHeaders(): HeadersMap {
    const headers: HeadersMap = {};

    if (this.accessToken) {
      headers['Authorization'] = `Bearer ${this.accessToken}`;
    }

    return headers;
  }

  private toFormData(params: Record<string, any>): FormData {
    const formData = new FormData();
    for (const [key, value] of Object.entries(params)) {
      if (value instanceof Blob) {
        formData.append(key, value, getFileName(value));
      } else {
        formData.append(key, value);
      }
    }
    return formData;
  }
}
